import axios from "axios";


const BASE_URL_CF = process.env.VUE_APP_CF_D2C

const BASE_URL_CF_AON = process.env.VUE_APP_CF_AON
const BASE_URL_API = process.env.VUE_APP_API_URL

export const zoneCosts = async (zipCode) => {
    try {
       const {data} = await axios.get(BASE_URL_CF + '/AIG_D2C_AON_MARSH_GET_ZONE?zipCode=' + zipCode)
       return data
    } catch (error) {
        throw error
    }
}

export const emiliPayments = async (body) => {
    try {
        const { data } = await axios.post(`${BASE_URL_CF}/AIG_AON_EXPLORER_PAYMENT_EMILI_PROCESS_PAYMENT_V3_TEST`, body)
        return data;
    } catch (error) {
        throw error;
    }
}

export const getPlans = async (planId, secureAmount, zona_hidro, zona_sismica, dataCustomer) => {
    try {

        const body = {
            planId, secureAmount, zona_hidro, zona_sismica, dataCustomer
        }
        const {data} = await axios.post(BASE_URL_CF_AON + '/GET_QUOTES_COVERAGES', body)
        
        return data
    } catch (error) {
        throw error
    }
}

export const validateToken = async (token) => {
    try {
        const response = await axios.get(`${BASE_URL_API}/auth/validate-token?token=${token}`);
        return response.data
    } catch (error) {
        console.log("ERROR:::",error.message);
    }
}

export const getQuotePDF = async (quoteId,zipCode,catastrofico) => {
    try {
        console.log('URL',process.env.VUE_APP_API_URL);
        return await axios.post(BASE_URL_API + '/_api/v1/quotes/downloadQuotePdf/',{quoteId,zipCode,catastrofico},{
        responseType: "blob",
      });
    } catch (error) {
        throw error
    }
}

export const convertToDollar = async () => {
    try {
        const response = await axios.get("https://openexchangerates.org/api/latest.json?app_id=a50fae975e3349f9bf6b1c480aeb5512");
        const values = response.data;
        const actualRateMXN = values.rates.MXN;
        return actualRateMXN;
    } catch (error) {
        throw error.message;
    }
}

export const saveProgressStatus = async (step, quoteId = null) => {
    try {
        const response = await axios.post(`${BASE_URL_CF_AON}/AIG_D2C_AON_TRACK_USER`, { step, quoteId })
        return response.data.quoteId;
    } catch (error) {
        throw error.message;
    }
}
